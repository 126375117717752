import { FIELD_NAMES, GROUP_FIELD_NAME_PREFIX } from 'const/forms'
import { ENTITY_NAMES } from 'const/api'
import moment from 'moment'
import { DATE_FORMAT_DASH_REVERSED } from 'const/dates'
import { PROGRAM_STATUSES } from 'const/shops'
import last from 'lodash/last'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import flatMap from 'lodash/flatMap'
import keys from 'lodash/keys'
import reduce from 'lodash/reduce'
import { MAP_SORTER_TO_FILTER_NAME, REDUCE_SORT_ORDER } from 'helpers/filters'
import { OFFER_TYPES } from 'const/coupons'

export const formatPayloadForFetchRequest = ({ search } = {}) => ({
  search,
})

export const formatPayloadForFetchLocalizedShopsRequest = ({
  search,
  filters,
  page,
  perPage,
} = {}) => {
  const formattedPayload = {
    search,
    filters,
    page,
    perPage,
  }
  return formattedPayload
}

export const formatShopsForUpdate = ({
  [FIELD_NAMES.id]: id,
  key,
  ...values
}) => ({
  ...(id && { id }),
  ...values,
})

export const formatLocalizedShopsForUpdate = ({
  [FIELD_NAMES.id]: id,
  key,
  ...values
}) => ({
  ...(id && { id }),
  type: ENTITY_NAMES.localizedShops,
  attributes: values,
})

export const formatCampaignForRequests = ({
  // Names according to the form - columns file
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.network]: network,
  [FIELD_NAMES.dealType]: deal_type,
  [FIELD_NAMES.flatFee]: flat_fee,
  [FIELD_NAMES.flatFeeCurrency]: flat_fee_currency,
  [FIELD_NAMES.fixedFee]: fixed_fee,
  [FIELD_NAMES.fixedFeeCurrency]: fixed_fee_currency,
  [FIELD_NAMES.currentCpaPercentage]: current_cpa_percentage,
  [FIELD_NAMES.newCpaPercentage]: new_cpa_percentage,
  [FIELD_NAMES.startDate]: start_date,
  [FIELD_NAMES.endDate]: end_date,
  [FIELD_NAMES.freeTextSlack]: free_text_slack,
  [FIELD_NAMES.notifySlack]: notify_slack,
  [FIELD_NAMES.shopId]: shop_id,
}) => {
  // Convert values to the format that is expected by the API
  const fixed_start_date = moment(start_date).format(DATE_FORMAT_DASH_REVERSED)
  const fixed_end_date = moment(end_date).format(DATE_FORMAT_DASH_REVERSED)
  const fixed_network = network?.value

  const flatFee = flat_fee ?? fixed_fee
  const flatFeeCurrency = flat_fee_currency ?? fixed_fee_currency

  // API names <- form / fixed names
  return {
    ...(id && { id }),
    type: 'campaign_deals',
    attributes: {
      [FIELD_NAMES.network]: fixed_network,
      [FIELD_NAMES.dealType]: deal_type,
      [FIELD_NAMES.flatFee]: flatFee,
      [FIELD_NAMES.flatFeeCurrency]: flatFeeCurrency,
      [FIELD_NAMES.currentCpaPercentage]: current_cpa_percentage,
      [FIELD_NAMES.newCpaPercentage]: new_cpa_percentage,
      [FIELD_NAMES.startDate]: fixed_start_date,
      [FIELD_NAMES.endDate]: fixed_end_date,
      [FIELD_NAMES.freeTextSlack]: free_text_slack,
      [FIELD_NAMES.notifySlack]: notify_slack,
      [FIELD_NAMES.shopId]: shop_id,
    },
  }
}

export const mapSorters = (sorter) => {
  if (!sorter?.order) return ''
  const { order, columnKey } = sorter
  if (columnKey.includes(GROUP_FIELD_NAME_PREFIX)) {
    const groupId = last(columnKey.split('_'))
    return `${REDUCE_SORT_ORDER[order]}group_priority&sort_params[group_id]=${groupId}`
  }
  return `${REDUCE_SORT_ORDER[order]}${MAP_SORTER_TO_FILTER_NAME[columnKey]}`
}

export const formatCampaign = (campaign) => {
  return {
    key: campaign[FIELD_NAMES.id],
    [FIELD_NAMES.id]: campaign[FIELD_NAMES.id],
    [FIELD_NAMES.network]: {
      value: campaign.attributes?.[FIELD_NAMES.network],
    },
    [FIELD_NAMES.dealType]: campaign.attributes?.[FIELD_NAMES.dealType],
    [FIELD_NAMES.flatFee]: campaign.attributes?.[FIELD_NAMES.flatFee],
    [FIELD_NAMES.flatFeeCurrency]:
      campaign.attributes?.[FIELD_NAMES.flatFeeCurrency],
    [FIELD_NAMES.fixedFee]: campaign.attributes?.[FIELD_NAMES.flatFee],
    [FIELD_NAMES.fixedFeeCurrency]:
      campaign.attributes?.[FIELD_NAMES.flatFeeCurrency],
    [FIELD_NAMES.currentCpaPercentage]:
      campaign.attributes?.[FIELD_NAMES.currentCpaPercentage],
    [FIELD_NAMES.newCpaPercentage]:
      campaign.attributes?.[FIELD_NAMES.newCpaPercentage],
    [FIELD_NAMES.startDate]: moment(
      campaign.attributes?.[FIELD_NAMES.startDate]
    ).format(DATE_FORMAT_DASH_REVERSED),
    [FIELD_NAMES.endDate]: moment(
      campaign.attributes?.[FIELD_NAMES.endDate]
    ).format(DATE_FORMAT_DASH_REVERSED),
    [FIELD_NAMES.reportingAdmin]:
      campaign.attributes?.[FIELD_NAMES.reportingAdmin],
    [FIELD_NAMES.freeTextSlack]:
      campaign.attributes?.[FIELD_NAMES.freeTextSlack],
    [FIELD_NAMES.confirmed]: campaign.attributes?.[FIELD_NAMES.confirmed],
    [FIELD_NAMES.active]: campaign.attributes?.[FIELD_NAMES.active],
  }
}

export const formatCampaigns = (campaigns = {}) => {
  return (
    Object.values(campaigns).reduce(
      (result, campaign) => ({
        ...result,
        [campaign[FIELD_NAMES.id]]: formatCampaign(campaign),
      }),
      {}
    ) || {}
  )
}

export const formatCampaignForUpdate = ({
  // Names according to the form - columns file
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.network]: network,
  [FIELD_NAMES.dealType]: deal_type,
  [FIELD_NAMES.flatFee]: flat_fee,
  [FIELD_NAMES.flatFeeCurrency]: flat_fee_currency,
  [FIELD_NAMES.fixedFee]: fixed_fee,
  [FIELD_NAMES.fixedFeeCurrency]: fixed_fee_currency,
  [FIELD_NAMES.currentCpaPercentage]: current_cpa_percentage,
  [FIELD_NAMES.newCpaPercentage]: new_cpa_percentage,
  [FIELD_NAMES.startDate]: start_date,
  [FIELD_NAMES.endDate]: end_date,
  [FIELD_NAMES.freeTextSlack]: free_text_slack,
  [FIELD_NAMES.notifySlack]: notify_slack,
  [FIELD_NAMES.shopId]: shop_id,
}) => {
  // Convert values to the format that is expected by the API
  const fixed_start_date = moment(start_date).format(DATE_FORMAT_DASH_REVERSED)
  const fixed_end_date = moment(end_date).format(DATE_FORMAT_DASH_REVERSED)
  const fixed_network = network?.value

  const flatFee = flat_fee ?? fixed_fee
  const flatFeeCurrency = flat_fee_currency ?? fixed_fee_currency

  // API names <- form / fixed names
  return {
    ...(id && { id }),

    [FIELD_NAMES.network]: fixed_network,
    [FIELD_NAMES.dealType]: deal_type,
    [FIELD_NAMES.flatFee]: ~~flatFee,
    [FIELD_NAMES.flatFeeCurrency]: flatFeeCurrency,
    [FIELD_NAMES.currentCpaPercentage]: current_cpa_percentage,
    [FIELD_NAMES.newCpaPercentage]: new_cpa_percentage,
    [FIELD_NAMES.startDate]: fixed_start_date,
    [FIELD_NAMES.endDate]: fixed_end_date,
    [FIELD_NAMES.freeTextSlack]: free_text_slack,
    [FIELD_NAMES.notifySlack]: notify_slack,
    [FIELD_NAMES.shopId]: shop_id,
  }
}

export const formatShopOfferForRequests = ({
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.ruleName]: rule,
  [FIELD_NAMES.ruleId]: rule_id,
  [FIELD_NAMES.retailerName]: retailer,
  [FIELD_NAMES.description]: description,
  [FIELD_NAMES.offerType]: offerType,
  [FIELD_NAMES.priority]: priority,
  [FIELD_NAMES.geos]: geos,
  [FIELD_NAMES.exclusive]: exclusive,
  [FIELD_NAMES.url]: url,
  [FIELD_NAMES.salesRep]: salesRep,
  [FIELD_NAMES.comments]: comments,
  [FIELD_NAMES.shopId]: shop_id,
  key,
  ...values
}) => {
  const isOfferTypeCoupons = offerType === OFFER_TYPES[0].value
  return {
    ...(id && { id }),
    ...(!id && { source: 'backoffice' }),
    description: description || null,
    [FIELD_NAMES.offerType]: offerType,
    [FIELD_NAMES.priority]: isOfferTypeCoupons ? priority : null,
    [FIELD_NAMES.geos]: isOfferTypeCoupons ? geos : null,
    [FIELD_NAMES.exclusive]: isOfferTypeCoupons ? exclusive : false,
    [FIELD_NAMES.url]: isOfferTypeCoupons ? url : null,
    [FIELD_NAMES.salesRep]: isOfferTypeCoupons ? salesRep : null,
    [FIELD_NAMES.comments]: isOfferTypeCoupons ? comments : null,
    [FIELD_NAMES.shopId]: shop_id,
    [FIELD_NAMES.ruleId]: rule_id,
    ...values,
  }
}

export const formatKeyValueAttr = ([flag, value]) => ({
  name: flag.replaceAll('_', ' '),
  value,
})

export const formatRange = (range) =>
  range
    ? {
        [FIELD_NAMES.lowerBound]: range[FIELD_NAMES.lowerBound],
        [FIELD_NAMES.upperBound]: range[FIELD_NAMES.upperBound],
      }
    : null

export const formatPayout = (payout) => ({
  [FIELD_NAMES.percentageRange]: formatRange(
    payout[FIELD_NAMES.percentageRange]
  ),
  [FIELD_NAMES.flatRange]: formatRange(payout[FIELD_NAMES.flatRange]),
})

export const formatActionPayout = (payout) => ({
  [FIELD_NAMES.name]: payout[FIELD_NAMES.name],
  [FIELD_NAMES.payout]: formatPayout(payout[FIELD_NAMES.payout]),
})

export const formatProgramActions = (action) => ({
  [FIELD_NAMES.name]: action[FIELD_NAMES.name],
  [FIELD_NAMES.type]: action[FIELD_NAMES.type],
  [FIELD_NAMES.defaultPayout]: action[FIELD_NAMES.defaultPayout]
    ? formatPayout(action[FIELD_NAMES.defaultPayout])
    : null,
  [FIELD_NAMES.specificPayouts]: map(
    action[FIELD_NAMES.specificPayouts] || [],
    formatActionPayout
  ),
})

export const getProgramStatus = (data, name) => {
  if (data[FIELD_NAMES.checked].includes(name)) return PROGRAM_STATUSES.checked

  if (data[FIELD_NAMES.failed].includes(name)) return PROGRAM_STATUSES.failed

  if (data[FIELD_NAMES.notChecked].includes(name))
    return PROGRAM_STATUSES.notChecked
}

export const formatShopProgram = (program) => ({
  [FIELD_NAMES.status]: program[FIELD_NAMES.status],
  [FIELD_NAMES.id]:
    program[FIELD_NAMES.ruleId] + program[FIELD_NAMES.programId],
  [FIELD_NAMES.programId]: program[FIELD_NAMES.programId],
  [FIELD_NAMES.websiteId]: program[FIELD_NAMES.websiteId],
  [FIELD_NAMES.ruleId]: program[FIELD_NAMES.ruleId],
  [FIELD_NAMES.network]: program[FIELD_NAMES.network],
  [FIELD_NAMES.name]: program[FIELD_NAMES.name],
  [FIELD_NAMES.domain]: program[FIELD_NAMES.domain],
  [FIELD_NAMES.flags]: program[FIELD_NAMES.flags],
  [FIELD_NAMES.features]: program[FIELD_NAMES.features],
  [FIELD_NAMES.epcs]: program[FIELD_NAMES.epcs],
  [FIELD_NAMES.actions]: map(
    program[FIELD_NAMES.actions] || [],
    formatProgramActions
  ),
})

export const getAllKeys = (data, key) =>
  uniq(flatMap(data || {}, (obj) => keys(obj[key]))) || []

export const formatShopPrograms = (data) => ({
  [FIELD_NAMES.entities]: reduce(
    data[FIELD_NAMES.checked],
    (result, item) => ({
      ...result,
      [item]: formatProgram(item, data),
    }),
    {}
  ),
  [FIELD_NAMES.checked]: data[FIELD_NAMES.checked],
  [FIELD_NAMES.failed]: data[FIELD_NAMES.failed],
  [FIELD_NAMES.notChecked]: data[FIELD_NAMES.notChecked],
  [FIELD_NAMES.rows]: map(data[FIELD_NAMES.rows], (program) =>
    formatShopProgram(program, data)
  ),
})

export const formatProgram = (name, data) => {
  const rows = data[FIELD_NAMES.rows].filter(
    (row) => row[FIELD_NAMES.network] === name
  )

  return {
    [FIELD_NAMES.name]: name,
    [FIELD_NAMES.status]: getProgramStatus(data, name),
    [FIELD_NAMES.features]: getAllKeys(rows, FIELD_NAMES.features),
    [FIELD_NAMES.epcs]: getAllKeys(rows, FIELD_NAMES.epcs),
    [FIELD_NAMES.flags]: getAllKeys(rows, FIELD_NAMES.flags),
  }
}
