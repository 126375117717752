import { FIELD_NAMES, JAVA_FIELD_NAMES } from 'const/forms'
import { TRANSACTION_STATUSES } from 'const/transactions'

export const paymentTransactionsColumns = (filters, sorter, networks) => [
  {
    title: 'Id',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    sorter: true,
    sortOrder: sorter?.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Sale value',
    dataIndex: JAVA_FIELD_NAMES.commissionOrderValue,
    key: JAVA_FIELD_NAMES.commissionOrderValue,
    sorter: true,
    sortOrder:
      sorter?.columnKey === JAVA_FIELD_NAMES.commissionOrderValue &&
      sorter.order,
    render: (value, data) =>
      value.toLocaleString('en-US', {
        style: 'currency',
        currency: data[JAVA_FIELD_NAMES.commissionCurrency],
      }),
  },
  {
    title: 'Commission value',
    dataIndex: JAVA_FIELD_NAMES.commissionValue,
    key: JAVA_FIELD_NAMES.commissionValue,
    render: (value, data) =>
      value.toLocaleString('en-US', {
        style: 'currency',
        currency: data[JAVA_FIELD_NAMES.commissionCurrency],
      }),
    sorter: true,
    sortOrder:
      sorter?.columnKey === JAVA_FIELD_NAMES.commissionValue && sorter.order,
  },
  {
    title: 'Network',
    dataIndex: JAVA_FIELD_NAMES.network,
    key: JAVA_FIELD_NAMES.network,
    sorter: true,
    sortOrder: sorter?.columnKey === JAVA_FIELD_NAMES.network && sorter.order,
    filters: networks,
    filteredValue: filters[JAVA_FIELD_NAMES.network] || null,
    filterMultiple: false,
    render: (value, data) =>
      networks.find((network) => network.value === value)?.text ||
      data[JAVA_FIELD_NAMES.network],
  },
  {
    title: 'Domain',
    dataIndex: JAVA_FIELD_NAMES.retailerDomain,
    key: JAVA_FIELD_NAMES.retailerDomain,
    sorter: true,
    sortOrder:
      sorter?.columnKey === JAVA_FIELD_NAMES.retailerDomain && sorter.order,
  },
  {
    title: 'Status',
    dataIndex: JAVA_FIELD_NAMES.status,
    key: JAVA_FIELD_NAMES.status,
    sorter: true,
    sortOrder: sorter?.columnKey === JAVA_FIELD_NAMES.status && sorter.order,
    filters: TRANSACTION_STATUSES,
    filteredValue: filters[JAVA_FIELD_NAMES.status] || null,
    onFilter: (value, record) => record[JAVA_FIELD_NAMES.status] === value,
  },
  {
    title: 'Created at',
    dataIndex: JAVA_FIELD_NAMES.createdAt,
    key: JAVA_FIELD_NAMES.createdAt,
    sorter: true,
    sortOrder: sorter?.columnKey === JAVA_FIELD_NAMES.createdAt && sorter.order,
  },
]
