import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  setSearch,
  fetchShopsSuccess,
  fetchShopsFailure,
  fetchLocalizedShopsSuccess,
  fetchLocalizedShopsFailure,
  fetchShopCampaignDealsSuccess,
  fetchShopCampaignDealsFailure,
  fetchShopAdPackagesSuccess,
  fetchShopAdPackagesFailure,
  fetchShopOffersSuccess,
  fetchShopOffersFailure,
  fetchShopProgramsSuccess,
  fetchShopProgramsFailure,
  fetchShopsToVerifySuccess,
  fetchShopsToVerifyFailure,
  startSaving,
  finishSaving,
  clearShops,
  clearData,
} = reducer.actions

export const fetchShops = createAction('shops/fetchShops')
export const fetchLocalizedShops = createAction('shops/fetchLocalizedShops')
export const fetchShopCampaignDeals = createAction(
  'shops/fetchShopCampaignDeals'
)
export const fetchShopAdPackages = createAction('shops/fetchShopAdPackages')
export const fetchShopOffers = createAction('shops/fetchShopOffers')

export const fetchShopPrograms = createAction('shops/fetchShopPrograms')

export const updateShop = createAction('shops/updateShop')
export const updateShopSuccess = createAction('shops/updateShopsSuccess')
export const updateShopFailure = createAction('shops/updateShopFailure')

export const updateLocalizedShop = createAction('shops/updateLocalizedShop')
export const updateLocalizedShopSuccess = createAction(
  'shops/updateLocalizedShopsSuccess'
)
export const updateLocalizedShopFailure = createAction(
  'shops/updateLocalizedShopFailure'
)

export const updateShopCouponsSettings = createAction(
  'shops/updateShopCouponsSettings'
)
export const updateShopCouponsSettingsSuccess = createAction(
  'shops/updateShopCouponsSettingsSuccess'
)
export const updateShopCouponsSettingsFailure = createAction(
  'shops/updateShopCouponsSettingsFailure'
)

export const createShopCampaign = createAction('shops/campaigns/createCampaign')

export const createShopCampaignSuccess = createAction(
  'shops/campaigns/createShopCampaignSuccess'
)

export const createShopCampaignFailure = createAction(
  'shops/campaigns/createShopCampaignFailure'
)

export const updateShopCampaign = createAction(
  'shops/campaigns/updateShopCampaign'
)
export const updateShopCampaignSuccess = createAction(
  'shops/campaigns/updateShopCampaignSuccess'
)
export const updateShopCampaignFailure = createAction(
  'shops/campaigns/updateShopCampaignFailure'
)

export const deleteCampaign = createAction('shops/campaigns/deleteCampaign')
export const deleteCampaignSuccess = createAction(
  'shops/campaigns/deleteCampaignSuccess'
)
export const deleteCampaignFailure = createAction(
  'shops/campaigns/deleteCampaignFailure'
)

export const createShopOffer = createAction('shops/offers/createShopOffer')
export const createShopOfferSuccess = createAction(
  'shops/offers/createShopOfferSuccess'
)
export const createShopOfferFailure = createAction(
  'shops/offers/createShopOfferFailure'
)

export const updateShopOffer = createAction('shops/offers/updateShopOffer')
export const updateShopOfferSuccess = createAction(
  'shops/offers/updateShopOfferSuccess'
)
export const updateShopOfferFailure = createAction(
  'shops/offers/updateShopOfferFailure'
)

export const deleteAdPackage = createAction('shops/adPackages/deleteAdPackage')
export const deleteAdPackageSuccess = createAction(
  'shops/adPackages/deleteAdPackageSuccess'
)
export const deleteAdPackageFailure = createAction(
  'shops/adPackages/deleteAdPackageFailure'
)

export const deleteShopOffer = createAction('shops/offers/deleteShopOffer')
export const deleteShopOfferSuccess = createAction(
  'shops/offers/deleteShopOfferSuccess'
)
export const deleteShopOfferFailure = createAction(
  'shops/offers/deleteShopOfferFailure'
)
