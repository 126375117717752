import { RELATIONSHIPS_NAMES } from 'const/api'
import { DEFAULT_MEDIA_TYPE } from 'const/adPackage'
import { FIELD_NAMES } from 'const/forms'

export const formatAdPackage = (adPackage, assets) => {
  const {
    [FIELD_NAMES.countryCode]: countryCode,
    label: name,
    value: retailerId,
  } = adPackage.attributes.retailer
  const description = (countryCode && ` (${countryCode})`) || ''
  const label = `${retailerId} - ${name + description}`
  const retailerName = description ? name + description : name
  const assetId =
    adPackage.relationships[RELATIONSHIPS_NAMES.packageAsset]?.data?.id
  const asset = assets?.[assetId] || {}
  const mediaType =
    adPackage.attributes[FIELD_NAMES.mediaType] || DEFAULT_MEDIA_TYPE
  return {
    ...adPackage.attributes,
    [FIELD_NAMES.retailerName]: retailerName,
    retailer: {
      ...adPackage.attributes.retailer,
      label,
    },
    id: adPackage.id,
    ...asset.attributes,
    ad_package_asset_id: assetId,
    [FIELD_NAMES.regions]: adPackage.attributes[FIELD_NAMES.regions] || [],
    [FIELD_NAMES.mediaType]: mediaType,
  }
}
