import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Layout, Button, Spin } from 'antd'
import { Form } from 'react-final-form'
import { diff } from 'deep-object-diff'
import isArray from 'lodash/isArray'
import {
  getIsLoaded,
  getPage,
  getPageCount,
  getSearchString,
  getIsSavingActive,
  getIsDataFilteredOrSorted,
  getFilters,
  getSorter,
} from 'domains/retailers/selectors'
import {
  openStoreChangesModal,
  openCommentsModal,
  openConfirmModal,
  openEditStoreContentModal,
  openGlobalUpdateModal,
  openInfoModal,
  openEditStoreImagesModal,
} from 'reducers/modals/actions'
import FormStateToRedux from 'components/FormStateToRedux'
import {
  changePage,
  changeSearchString,
  changeFiltersOrSorter,
  resetSavedValues,
  resetRetailerChanges,
  changeGlobal,
  resetChangedValues,
} from 'domains/retailers/actions'
import SearchInTableBar from 'components/SearchInTableBar'
import { StyledTable, Buttons } from './Retailers.styles'
import useAction from 'utils/useAction'
import { FORM_NAMES, GROUP_FIELD_NAME_PREFIX } from 'const/forms'
import { isFormChangesEmpty } from 'reducers/forms/selectors'
import { DESCRIPTION_FORMATTERS } from 'helpers/formatters/forms'
import { CORRECT_DATA } from 'const/errors'
import { selectors as generalSelectors } from 'domains/general'
import {
  MAP_FORM_NAME_TO_COLUMNS,
  MAP_FORM_NAME_TO_DATA_SOURCE,
  MAP_FORM_NAME_TO_INITIAL_VALUES,
  SCROLL,
} from './const'
import { HEADER_OFFSET } from 'const/tables'
import { getRowKey, getRowClass } from 'helpers/tables'
import { formatGroupCellField } from 'helpers/formatters/fields'

const Retailers = ({ formName }) => {
  const withCategories = formName === FORM_NAMES.EDITORIAL_CHANGES
  const getColumns = MAP_FORM_NAME_TO_COLUMNS[formName]
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)
  const groups = useSelector(generalSelectors.getGroups)
  const unFormattedGroups = useSelector(generalSelectors.getUnformattedGroups)
  const isSavingActive = useSelector(getIsSavingActive)
  const dataSource = useSelector(MAP_FORM_NAME_TO_DATA_SOURCE[formName])
  const initialValues = useSelector(MAP_FORM_NAME_TO_INITIAL_VALUES[formName])
  const isChangesEmpty = useSelector(isFormChangesEmpty)(formName)
  const isLoadingActive = !isLoaded || isSavingActive

  const changeSearchStringFunc = useAction(changeSearchString)
  const changeFiltersOrSorterFunc = useAction(changeFiltersOrSorter)
  const changePageFunc = useAction(changePage)
  const resetRetailerChangesFunc = useAction(resetRetailerChanges)
  const resetSavedValuesFunc = useAction(resetSavedValues)
  const openStoreChangesModalFunc = useAction(openStoreChangesModal)
  const openCommentsModalFunc = useAction(openCommentsModal)
  const openEditStoreContentModalFunc = useAction(openEditStoreContentModal)
  const openEditStoreImagesModalFunc = useAction(openEditStoreImagesModal)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openInfoModalFunc = useAction(openInfoModal)
  const openGlobalUpdateModalFunc = useAction(openGlobalUpdateModal)
  const resetChangedValuesFunc = useAction(resetChangedValues)
  const changeGlobalFunc = useAction(changeGlobal)

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * 10,
    pageSize: 10,
    current: page,
    showSizeChanger: false,
  }

  const handleClearFieldValue =
    (changeFieldState) => (fieldName, fieldValue) => {
      resetRetailerChangesFunc(fieldName)
      changeFieldState(fieldName, fieldValue)
    }

  const openSubmitModal = (values, form) => {
    openStoreChangesModalFunc({
      changeFieldState: handleClearFieldValue(form.change),
      formName,
    })
  }

  const checkInvalidFormState =
    (callback, hasErrors) =>
    (...args) => {
      if (!hasErrors) {
        callback(...args)
      } else {
        openInfoModalFunc({
          text: CORRECT_DATA,
        })
      }
    }

  const onPageChange = (values, dirtyFields) => (page) =>
    changePageFunc({
      withCategories,
      values,
      dirtyFields,
      search,
      changes: diff(initialValues, values),
      page,
    })

  const onSearchStringChange = (values, dirtyFields) => (search) =>
    changeSearchStringFunc({
      withCategories,
      values,
      dirtyFields,
      changes: diff(initialValues, values),
      search,
    })

  const onTableChange = (values) => (pagination, filters, sorter) =>
    changeFiltersOrSorterFunc({
      values,
      search,
      changes: diff(initialValues, values),
      withCategories,
      filters,
      sorter,
    })

  const handleResetFilters = (values) => () =>
    changeFiltersOrSorterFunc({
      values,
      search,
      changes: diff(initialValues, values),
      withCategories,
      filters: {},
      sorter: {},
    })

  const onGlobalChange = (options) => (value) => {
    if (!options.isGlobal || !options.hasRegionalRetailers) return

    const { description } = (
      options.fieldName.includes(GROUP_FIELD_NAME_PREFIX)
        ? formatGroupCellField
        : DESCRIPTION_FORMATTERS[options.fieldName]
    )({
      name: options.fieldName,
      fieldName: options.fieldName,
      newValue: value,
      groups,
    })
    openGlobalUpdateModalFunc({
      text: 'Would you like to apply change to all regional retailers?',
      description: `Retailer ${options.name}. ID:${options.id}\n${
        isArray(description) ? description.join('\n') : description
      }`,
      callback: () => changeGlobalFunc({ ...options, value }),
    })
  }

  useEffect(() => {
    if (isChangesEmpty) {
      resetChangedValuesFunc()
    }
  }, [isChangesEmpty, resetChangedValuesFunc])

  return (
    <Layout.Content>
      <Form
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={openSubmitModal}
        render={({
          hasValidationErrors,
          handleSubmit,
          form: { change, setConfig, reset },
          values,
        }) => {
          const handleResetForm = () => {
            setConfig('keepDirtyOnReinitialize', false)
            reset()
            resetSavedValuesFunc({ doNotResetPage: true })
            setConfig('keepDirtyOnReinitialize', true)
          }
          const checkFilter = (callback) => (value) => {
            if (isChangesEmpty) {
              callback(value)
            } else {
              openConfirmModalFunc({
                callback: () => {
                  handleResetForm()
                  callback(value)
                },
              })
            }
          }
          const columnOptions = {
            filters,
            sorter,
            openCommentsModal: openCommentsModalFunc,
            checkFilter,
            values,
            openEditStoreImagesModal: openEditStoreImagesModalFunc,
            openEditStoreContentModal: openEditStoreContentModalFunc,
            handleGlobalChange: onGlobalChange,
            groups: unFormattedGroups,
          }
          return (
            <Spin spinning={isLoadingActive} size="large">
              <SearchInTableBar
                placeholder="Search retailer here"
                onSubmit={checkInvalidFormState(
                  onSearchStringChange(values),
                  hasValidationErrors
                )}
                value={search}
              >
                <Buttons>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={
                      isChangesEmpty || hasValidationErrors || !isLoaded
                    }
                  >
                    Save changes
                  </Button>
                  <Button
                    onClick={handleResetFilters(values)}
                    disabled={isLoadingActive || !isDataFilteredOrSorted}
                  >
                    Reset filters
                  </Button>
                </Buttons>
              </SearchInTableBar>
              <FormStateToRedux
                onChangeFieldState={handleClearFieldValue(change)}
                onResetForm={handleResetForm}
                form={formName}
                isMultipleSaveActive
              />
              <StyledTable
                onChange={checkInvalidFormState(
                  onTableChange(values),
                  hasValidationErrors
                )}
                showSorterTooltip={false}
                columns={getColumns(columnOptions)}
                dataSource={dataSource}
                pagination={paginationConfig}
                expandable={false}
                sticky={HEADER_OFFSET}
                scroll={SCROLL}
                rowKey={getRowKey}
                rowClassName={getRowClass}
              />
            </Spin>
          )
        }}
      />
    </Layout.Content>
  )
}

Retailers.propTypes = {
  formName: PropTypes.string.isRequired,
}

export default Retailers
