import React from 'react'
import useAction from 'utils/useAction'
import { Spin, Typography } from 'antd'
import { markExtensionPassedReview } from 'actions/views/extensionVersions'
import { Wrapper } from './ExtensionVersions.styles'
import { Field, Form } from 'react-final-form'
import { validAppVersion } from '../../helpers/validators'
import { FIELD_NAMES } from '../../const/forms'
import Input from '../../components/Input/Input'
import { useSelector } from 'react-redux'
import { getIsSavingActive } from 'domains/extensionVersions/selectors'
import { StyledButton, StyledForm } from './ExtensionVersions.styles'

const ExtensionVersions = () => {
  const isSavingActive = useSelector(getIsSavingActive)

  const markExtensionPassedReviewFunc = useAction(markExtensionPassedReview)

  const onSubmit = (value) => {
    markExtensionPassedReviewFunc({ value })
  }

  return (
    <Wrapper>
      <Typography.Title level={3}>
        Mark extension as passed review
      </Typography.Title>
      <Form
        destroyOnUnregister
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine }) => (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              <Field
                required
                validate={validAppVersion}
                name={FIELD_NAMES.version}
                label="Version"
                placeholder="Version"
                component={Input}
              />
              <StyledButton
                type="primary"
                disabled={pristine}
                onClick={handleSubmit}
              >
                Update version
              </StyledButton>
            </StyledForm>
          </Spin>
        )}
      />
    </Wrapper>
  )
}

export default ExtensionVersions
