import React from 'react'
import { DatePicker as AntDatePicker } from 'antd'
import moment from 'moment'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'
import { DATE_TIME_FORMAT } from '../../const/dates'

const DatePicker = ({ required, input, meta, ...rest }) => (
  <FieldWrapper label={rest.label} required={required}>
    <AntDatePicker
      format={DATE_TIME_FORMAT}
      value={input.value ? moment(input.value) : null}
      onChange={input.onChange}
      renderExtraFooter={() => `UTC${moment().format('Z')} time zone`}
      {...rest}
    />
  </FieldWrapper>
)

export default DatePicker
