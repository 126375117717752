import { all, put, takeLatest } from 'redux-saga/effects'
import * as actions from 'actions/layout'
import { actions as userActions } from 'domains/user'
import { actions as adminActions } from 'domains/admin'
import { actions as adPackagesActions } from 'domains/adPackages'
import { actions as bonusesActions } from 'domains/bonuses'
import { actions as couponsActions } from 'domains/coupons'
import { actions as campaignsActions } from 'domains/campaigns'
import { actions as featuresActions } from 'domains/features'
import { actions as generalActions } from 'domains/general'
import { actions as notificationsActions } from 'domains/notifications'
import { actions as paymentsActions } from 'domains/payments'
import { actions as productsActions } from 'domains/products'
import { actions as retailersActions } from 'domains/retailers'
import { actions as rulesActions } from 'domains/rules'
import { actions as pitaSettings } from 'domains/pitaSettings'
import { actions as shops } from 'domains/shops'
import { actions as bonuses } from 'domains/bonuses'
import { actions as transactions } from 'domains/transactions'
import { actions as paymentTransactions } from 'domains/paymentTransactions'

function* clearAppData() {
  yield all([
    put(userActions.clearData()),
    put(adPackagesActions.clearData()),
    put(bonusesActions.clearData()),
    put(couponsActions.clearData()),
    put(campaignsActions.clearData()),
    put(featuresActions.clearData()),
    put(generalActions.clearData()),
    put(notificationsActions.clearData()),
    put(productsActions.clearData()),
    put(paymentsActions.clearData()),
    put(rulesActions.clearData()),
    put(retailersActions.clearData()),
    put(pitaSettings.clearData()),
    put(shops.clearData()),
    put(bonuses.clearData()),
    put(adminActions.clearData()),
    put(transactions.clearData()),
    put(paymentTransactions.clearData()),
  ])
}

function* logoutWorker() {
  yield put(adminActions.adminLogout())
  yield put(actions.clearData())
}

export default function* () {
  yield all([
    takeLatest(actions.logout, logoutWorker),
    takeLatest(actions.clearData, clearAppData),
  ])
}
