import { createAction } from '@reduxjs/toolkit'

export const fetchShops = createAction('shop/fetchShops')
export const fetchLocalizedShops = createAction('shop/fetchLocalizedShops')
export const updateShop = createAction('shop/updateShop')
export const updateLocalizedShop = createAction('shop/updateLocalizedShop')
export const updateShopCouponsSettings = createAction(
  'shop/updateShopCouponsSettings'
)
export const fetchShopCampaignDeals = createAction(
  'shop/fetchShopCampaignDeals'
)
export const fetchShopAdPackages = createAction('shop/fetchShopAdPackages')
export const fetchShopOffers = createAction('shop/fetchShopOffers')

export const createShopCampaign = createAction('shop/campaign/createCampaign')
export const updateShopCampaign = createAction('shop/campaign/updateCampaign')

export const createShopOffer = createAction('shop/offer/createOffer')
export const updateShopOffer = createAction('shop/offer/updateOffer')

export const deleteCampaign = createAction('shop/campaign/deleteCampaign')
export const deleteAdPackage = createAction('shop/adPackage/deleteAdPackage')
export const deleteShopOffer = createAction('shop/offer/deleteOffer')
export const fetchShopPrograms = createAction('shop/fetchShopPrograms')
