import { LOCATION_CHANGE } from 'connected-react-router'
import { takeEvery, cancel, fork, take, all } from 'redux-saga/effects'

import ROUTES from 'const/routes'

// view sagas
import customerService from './views/customerService'
import retailers from './views/retailers'
import deleted from './views/deleted'
import adPackages from './views/adPackages'
import features from './views/features'
import insights from './views/insights'
import coupon from './views/coupon'
import payments from './views/payments'
import users from './views/users'
import emailNotification from './views/emailNotification'
import mobileNotifications from './views/mobileNotifications'
import roles from './views/roles'
import mobileVersions from './views/mobileVersions'
import extensionVersions from './views/extensionVersions'
import settings from './views/settings'
import linkConverter from './views/linkConverter'
import ruleSlugs from './views/ruleSlugs'
import landingPages from './views/landingPages'
import marketingPages from './views/marketingPages'
import SEOPages from './views/SEOPages'
import giveaways from './views/giveaways'
import developers from './views/developers'
import campaign from './views/campaign'
import pitaSettings from './views/pitaSettings'
import shops from './views/shops'
import adminLogs from './views/adminLogs'
import bonuses from './views/bonuses'
import transactions from './views/transactions'
import paymentTransactions from './views/paymentTransactions'

import { initStoreSaga, routingSaga } from './initialize'

const viewSagas = {
  [ROUTES.RETAILERS]: retailers,
  [ROUTES.MOBILE_APP_VERSION]: mobileVersions,
  [ROUTES.EXTENSION_VERSIONS]: extensionVersions,
  [ROUTES.EDITORIAL]: retailers,
  [ROUTES.AD_PACKAGES]: adPackages,
  [ROUTES.INSIGHTS]: insights,
  [ROUTES.LINK_CONVERTER]: linkConverter,
  [ROUTES.SETTINGS]: settings,
  [ROUTES.LANDING_PAGE_IMAGES]: landingPages,
  [ROUTES.MARKETING_PAGES]: marketingPages,
  [ROUTES.SEO_PAGES]: SEOPages,
  [ROUTES.GIVEAWAYS]: giveaways,
  [ROUTES.FEATURES]: features,
  [ROUTES.DELETED_STORES]: deleted,
  [ROUTES.COUPONS]: coupon,
  [ROUTES.CAMPAIGN_DEALS]: campaign,
  [ROUTES.PAYMENTS]: payments,
  [ROUTES.CUSTOMER_SERVICE]: customerService,
  [ROUTES.USERS]: users,
  [ROUTES.EMAIL_NOTIFICATIONS]: emailNotification,
  [ROUTES.MOBILE_NOTIFICATIONS]: mobileNotifications,
  [ROUTES.ROLES]: roles,
  [ROUTES.RULE_SLUGS]: ruleSlugs,
  [ROUTES.SHOP_DEV]: developers,
  [ROUTES.PITA]: pitaSettings,
  [ROUTES.HOME]: [shops, adPackages],
  [ROUTES.ADMIN_LOGS]: adminLogs,
  [ROUTES.BONUSES]: bonuses,
  [ROUTES.TRANSACTIONS_BY_DATE]: transactions,
  [ROUTES.PAYMENT_TRANSACTIONS]: paymentTransactions,
}

let task = null

function* onchange(action) {
  const { hash, pathname, search } = action.payload.location

  if (task) {
    if (Array.isArray(task)) {
      yield all(task.map((subtask) => cancel(subtask)))
    } else {
      yield cancel(task)
    }
  }

  if (pathname in viewSagas) {
    if (Array.isArray(viewSagas[pathname])) {
      task = yield all(
        viewSagas[pathname].map((saga) => fork(saga, search, hash))
      )
    } else {
      task = yield fork(viewSagas[pathname], search, hash)
    }
  }
}

export default function* routerSaga() {
  yield take(LOCATION_CHANGE)
  yield initStoreSaga()
  yield routingSaga()
  yield takeEvery(LOCATION_CHANGE, onchange)
}
