import { apiInstance3 } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import parseError from 'helpers/parseServerError'

export const markExtensionPassedReview = async (version) => {
  try {
    return await apiInstance3.put(
      `/${RELATIONSHIPS_NAMES.backoffice}/${ENTITY_NAMES.extensionVersions}/mark_as_pass_review`,
      { version }
    )
  } catch (error) {
    parseError(error, 'Failed to mark extension as passed review')
  }
}
