import { apiInstance3, javaApiInstance } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import parseError from 'helpers/parseServerError'
import { mapSorters } from './formatters'
import { FIELD_NAMES } from 'const/forms'

export const getShops = ({ search }) =>
  apiInstance3
    .get(`/backoffice/${ENTITY_NAMES.shops}?search=${search.toLowerCase()}`)
    .catch((error) => {
      parseError(error, 'Cannot get shops')
    })

export const getLocalizedShops = ({ search, filters, page, perPage }) =>
  apiInstance3
    .get(`/backoffice/${ENTITY_NAMES.shops}/${search}`, {
      params: {
        include: 'localized_shops',
        localized_shops_filter: filters,
        page: page || 1,
        per_page: perPage || 10,
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get localized shops')
    })

export const getShopCampaignDeals = ({
  id,
  filters,
  sorter,
  page,
  perPage,
}) => {
  const mappedSorter = mapSorters(sorter)
  const params = {
    include: 'campaign_deals',
    page: page || 1,
    per_page: perPage || 20,
  }

  if (filters && !!Object.keys(filters).length) {
    params.campaign_deals_filter = filters
  }
  if (!!mappedSorter) {
    params.campaign_deals_sorter = mappedSorter
  }

  return apiInstance3
    .get(`/backoffice/${ENTITY_NAMES.shops}/${id}`, {
      params,
    })
    .catch((error) => {
      parseError(error, 'Cannot get campaign deals')
    })
}

export const getShopAdPackages = ({ id, filters, sorter, page, perPage }) => {
  const mappedSorter = mapSorters(sorter)
  const params = {
    include: 'ad_packages',
    page: page || 1,
    per_page: perPage || 20,
  }

  if (filters && !!Object.keys(filters).length)
    params.ad_packages_filter = filters
  if (!!mappedSorter) params.ad_packages_sort = mappedSorter

  return apiInstance3
    .get(`/backoffice/${ENTITY_NAMES.shops}/${id}`, {
      params,
    })
    .catch((error) => {
      parseError(error, 'Cannot get ad packages')
    })
}

export const getShopPrograms = ({ id, filters, sorter }) => {
  return javaApiInstance
    .get(
      `/${ENTITY_NAMES.admin}/v1/${ENTITY_NAMES.program}/${ENTITY_NAMES.shop}/${id}`
    )
    .catch((error) => {
      parseError(error, 'Cannot get shop programs')
    })
}

export const getShopOffers = ({ id, filters, sorter, page, perPage }) => {
  const mappedSorter = mapSorters(sorter)
  const params = {
    include: 'shop_offers',
    page: page || 1,
    per_page: perPage || 20,
  }

  if (filters && !!Object.keys(filters).length)
    params.shop_offers_filter = filters
  if (!!mappedSorter) params.shop_offers_sort = mappedSorter
  if (page) {
    params.shop_offers_page = page || 1
    params.shop_offers_per_page = perPage || 20
  }

  return apiInstance3
    .get(`/backoffice/${ENTITY_NAMES.shops}/${id}`, {
      params,
    })
    .catch((error) => {
      parseError(error, 'Cannot get offers')
    })
}

export const updateShop = ({ id, ...rest }) =>
  apiInstance3
    .put(`/backoffice/${ENTITY_NAMES.shops}/${id}`, { ...rest })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Shop hasn't been updated")
    })

export const updateLocalizedShop = ({ id, data }) =>
  apiInstance3
    .put(`/backoffice/${ENTITY_NAMES.localizedShops}/${id}`, data.attributes)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Shop hasn't been updated")
    })

export const updateShopCouponsSettings = ({ id, data }) =>
  apiInstance3
    .put(
      `/backoffice/${ENTITY_NAMES.rules}/${id}/${ENTITY_NAMES.updateCouponsUsage}`,
      data
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Coupons haven't been updated")
    })

export const createShopCampaign = (data) => {
  const notify = data?.[FIELD_NAMES.notifySlack]
  delete data?.[FIELD_NAMES.notifySlack]

  return apiInstance3
    .post(
      `/backoffice/${RELATIONSHIPS_NAMES.campaignDeals}?notify_slack=${notify}`,
      data
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Campaign hasn't been created")
    })
}

export const updateShopCampaign = (data) => {
  const notify = data?.[FIELD_NAMES.notifySlack]
  delete data?.[FIELD_NAMES.notifySlack]

  return apiInstance3
    .put(
      `/backoffice/${RELATIONSHIPS_NAMES.campaignDeals}/${data.id}?notify_slack=${notify}`,
      data
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Campaign hasn't been created")
    })
}

export const createShopOffer = (data) => {
  return apiInstance3
    .post(`/backoffice/${RELATIONSHIPS_NAMES.shopOffers}`, data)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Offer hasn't been created")
    })
}

export const updateShopOffer = (data) => {
  return apiInstance3
    .put(`/backoffice/${RELATIONSHIPS_NAMES.shopOffers}/${data.id}`, data)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Offer hasn't been updated")
    })
}

export const deleteCampaign = (id) =>
  apiInstance3
    .delete(`/backoffice/${RELATIONSHIPS_NAMES.campaignDeals}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, 'Campaign has not been deleted')
    })

export const deleteAdPackage = (id) =>
  apiInstance3
    .delete(`/backoffice/${RELATIONSHIPS_NAMES.adPackages}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, 'Ad package has not been deleted')
    })

export const deleteShopOffer = (id) =>
  apiInstance3
    .delete(`/backoffice/${RELATIONSHIPS_NAMES.shopOffers}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, 'Offer has not been deleted')
    })
