import React, { useState } from 'react'
import { Avatar, Card, Divider, Typography } from 'antd'
import useAction from 'utils/useAction'
import { openShopModal } from 'reducers/modals/actions'
import { EditOutlined } from '@ant-design/icons'
import CheckboxIcon from 'components/CheckboxIcon'
import {
  ActionButton,
  CardWrapper,
  Parameter,
  ParametersContainer,
} from './ShopCard.style'

const { Paragraph, Title } = Typography

const ShopCard = ({ shop }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const openShopModalFunc = useAction(openShopModal)
  const { id } = shop || {}
  const handleEditShop = () => openShopModalFunc({ id })

  const onExpandOrCollapse = () => setIsDescriptionExpanded((prev) => !prev)

  const renderDescription = () => {
    return (
      <div key={isDescriptionExpanded ? 1 : 0}>
        <Paragraph
          ellipsis={
            !isDescriptionExpanded
              ? {
                  rows: 1,
                  expandable: true,
                  onExpand: onExpandOrCollapse,
                  symbol: 'more',
                }
              : false
          }
        >
          {shop?.attributes?.description}
          {isDescriptionExpanded && (
            <a onClick={onExpandOrCollapse} role="button">
              less
            </a>
          )}
        </Paragraph>
      </div>
    )
  }

  return (
    <CardWrapper>
      <Card
        actions={[
          <ActionButton key="edit" onClick={handleEditShop}>
            <EditOutlined /> Edit
          </ActionButton>,
        ]}
      >
        <Card.Meta
          avatar={<Avatar src={shop?.attributes?.logo} size="large" />}
          title={
            <Title level={4} style={{ margin: '4px 0 0' }}>
              {shop?.attributes?.name}{' '}
              <Divider
                type="vertical"
                style={{
                  height: '1.9em',
                  borderLeft: '2px solid rgba(0, 0, 0, 0.06)',
                }}
              />{' '}
              ID: {shop?.id}
              <Divider
                type="vertical"
                style={{
                  height: '1.9em',
                  borderLeft: '2px solid rgba(0, 0, 0, 0.06)',
                }}
              />{' '}
              ({shop?.attributes?.aggregated_tags_count || 0} tags)
            </Title>
          }
          description={renderDescription()}
        />
        <ParametersContainer>
          <Parameter>
            Available on search:
            {<CheckboxIcon checked={shop?.attributes?.available_on_search} />}
          </Parameter>
          <Divider type="vertical" style={{ height: '1.9em' }} />
          <Parameter>
            Shop page available:
            {<CheckboxIcon checked={shop?.attributes?.shop_page_available} />}
          </Parameter>
          <Divider type="vertical" style={{ height: '1.9em' }} />
          <Parameter>
            Verified:
            {<CheckboxIcon checked={shop?.attributes?.verified} />}
          </Parameter>

          {shop?.attributes?.priority && (
            <>
              <Divider type="vertical" style={{ height: '1.9em' }} />
              <Parameter>
                Priority: &nbsp;{shop?.attributes?.priority}
              </Parameter>
            </>
          )}
          {shop?.attributes?.aggregated_tags && (
            <>
              <Divider type="vertical" style={{ height: '1.9em' }} />
              <Parameter>
                Aggregated tags: &nbsp;{shop?.attributes?.aggregated_tags}
              </Parameter>
            </>
          )}
        </ParametersContainer>
      </Card>
    </CardWrapper>
  )
}

export default ShopCard
