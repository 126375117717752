import LocalizedShopsTab from '../pages/Shops/LocalizedShopsTab/LocalizedShopsTab'
import CampaignDealsTab from '../pages/Shops/CampaignDealsTab/CampaignDealsTab'
import AdPackagesTab from '../pages/Shops/AdPackagesTab/AdPackagesTab'
import ProgramsTab from '../pages/Shops/ProgramsTab'
import ShopOffersTab from '../pages/Shops/ShopOffersTab/ShopOffersTab'
import React from 'react'
import { SHOP_SUB_ENTITIES } from 'domains/shops/const'

export const SHOP_PROGRAM_ROW_STATUS_KEYS = {
  notSignedUp: 'not_signed_up',
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
  onHold: 'on_hold',
  suspended: 'suspended',
  signed_out: 'signed_out',
  notJoinedUnknown: 'not_joined_unknown',
}

export const AD_PACKAGE_TYPE_KEYS = {
  regular: 'regular_ad',
  coupon: 'coupon',
  extensionBanner: 'extension_banner',
}

export const RULE_TYPE_FILTERS = [
  { text: 'Regular', value: 'regular' },
  { text: 'Mobile', value: 'mobile' },
  { text: 'Checkout', value: 'checkout' },
  { text: 'Derivative', value: 'derivative' },
]

export const PACKAGE_TYPE_FILTERS = [
  { text: 'Regular', value: AD_PACKAGE_TYPE_KEYS.regular },
  { text: 'Coupon', value: AD_PACKAGE_TYPE_KEYS.coupon },
  { text: 'Extension banner', value: AD_PACKAGE_TYPE_KEYS.extensionBanner },
]

export const MAP_AD_PACKAGE_TYPE_TO_USER_TEXT = {
  [AD_PACKAGE_TYPE_KEYS.regular]: 'Regular',
  [AD_PACKAGE_TYPE_KEYS.coupon]: 'Coupon',
  [AD_PACKAGE_TYPE_KEYS.extensionBanner]: 'Extension banner',
}

export const PACKAGE_TYPE_OPTIONS = [
  {
    value: AD_PACKAGE_TYPE_KEYS.regular,
    label: MAP_AD_PACKAGE_TYPE_TO_USER_TEXT[AD_PACKAGE_TYPE_KEYS.regular],
  },
  {
    value: AD_PACKAGE_TYPE_KEYS.coupon,
    label: MAP_AD_PACKAGE_TYPE_TO_USER_TEXT[AD_PACKAGE_TYPE_KEYS.coupon],
  },
  {
    value: AD_PACKAGE_TYPE_KEYS.extensionBanner,
    label:
      MAP_AD_PACKAGE_TYPE_TO_USER_TEXT[AD_PACKAGE_TYPE_KEYS.extensionBanner],
  },
]

export const formatCountryCodesForFiltering = (countryCodes) => {
  return countryCodes?.map((code) => ({ text: code, value: code }))
}

export const TABS = [
  {
    key: SHOP_SUB_ENTITIES.localizedShops,
    label: 'Localized shops',
    children: <LocalizedShopsTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.campaignDeals,
    label: 'Campaign deals',
    children: <CampaignDealsTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.adPackages,
    label: 'Ad packages',
    children: <AdPackagesTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.shopOffers,
    label: 'Shop offers',
    children: <ShopOffersTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.programs,
    label: 'Programs',
    children: <ProgramsTab />,
  },
]

export const PROGRAM_STATUSES = {
  checked: 'checked',
  failed: 'failed',
  notChecked: 'notChecked',
}

export const STATUS_FILTERS = [
  { text: 'Checked', value: PROGRAM_STATUSES.checked },
  { text: 'Failed', value: PROGRAM_STATUSES.failed },
  { text: 'Not checked', value: PROGRAM_STATUSES.notChecked },
]

export const ACTIVE_FILTERS = [
  { text: 'Active', value: true },
  { text: 'Not Active', value: false },
]

export const MAP_STATUS_TO_USER_TEXT = {
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notSignedUp]: 'Not signed up',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.pending]: 'Pending',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.accepted]: 'Accepted',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.rejected]: 'Rejected',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.onHold]: 'On hold',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.suspended]: 'Suspended',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.signed_out]: 'Signed out',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notJoinedUnknown]: 'Not joined unknown',
}

export const MAP_STATUS_TO_EXPLANATION = {
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notSignedUp]: 'Karma can join the program',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.pending]: 'Karma has asked to join',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.accepted]: 'The program is active for Karma',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.rejected]: 'They refused to accept Karma',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.onHold]: 'Temporary suspension',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.suspended]: 'They removed Karma',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.signed_out]: 'Karma removed the program',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notJoinedUnknown]:
    'Karma is not joined, reason unknown',
}
