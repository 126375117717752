import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import * as shopsSelectors from './selectors'
import { formatCampaignForRequests, formatShopPrograms } from './formatters'

function* fetchShopsWorker({ payload }) {
  try {
    const { data } = yield call(api.getShops, payload)
    yield put(
      actions.fetchShopsSuccess({
        ...payload,
        shops: data.data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchShopsFailure())
  }
}

function* fetchLocalizedShopsWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const { data } = yield call(api.getLocalizedShops, payload)
    yield put(
      actions.fetchLocalizedShopsSuccess({
        ...payload,
        localizedShops: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchLocalizedShopsFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopCampaignDealsWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const body = formatCampaignForRequests(payload)
    const { data } = yield call(api.getShopCampaignDeals, payload)

    yield put(
      actions.fetchShopCampaignDealsSuccess({
        ...body,
        campaignDeals: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchLocalizedShopsFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopAdPackagesWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const { data } = yield call(api.getShopAdPackages, payload)

    yield put(
      actions.fetchShopAdPackagesSuccess({
        ...payload,
        adPackages: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchShopAdPackagesFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopOffersWorker({ payload }) {
  try {
    yield put(actions.startSaving())

    const { data } = yield call(api.getShopOffers, payload) || {}
    yield put(
      actions.fetchShopOffersSuccess({
        ...payload,
        shopOffers: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchShopOffersFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopProgramsWorker({ payload }) {
  try {
    const { data } = yield call(api.getShopPrograms, payload)

    yield put(
      actions.fetchShopProgramsSuccess({
        ...payload,
        programs: formatShopPrograms(data),
      })
    )

    yield put(actions.fetchShopProgramsFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* updateShopWorker({ payload }) {
  try {
    const { data } = yield call(api.updateShop, payload)
    const currentStore = yield select(shopsSelectors.getCurrentShopId)
    if (data) {
      yield put(actions.updateShopSuccess(payload))
      yield put(actions.fetchLocalizedShops({ search: currentStore }))
    } else {
      yield put(actions.updateShopFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopFailure())
  }
}

function* updateLocalizedShopWorker({ payload }) {
  try {
    const { data } = yield call(api.updateLocalizedShop, payload)
    if (data) {
      yield put(actions.updateLocalizedShopSuccess(payload))
    } else {
      yield put(actions.updateLocalizedShopFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateLocalizedShopFailure())
  }
}

function* updateShopCouponsSettingsWorker({ payload }) {
  try {
    const { data } = yield call(api.updateShopCouponsSettings, {
      id: payload.id,
      data: payload.data,
    })

    if (data) {
      const currentStore = yield select(shopsSelectors.getCurrentShopId)

      yield put(actions.updateShopCouponsSettingsSuccess(data))
      yield put(actions.fetchLocalizedShops({ search: currentStore }))
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopCouponsSettingsFailure())
  }
}

function* createShopCampaignWorker({ payload }) {
  try {
    const data = yield call(api.createShopCampaign, payload)
    if (data) {
      yield put(actions.createShopCampaignSuccess(payload))
    } else {
      yield put(actions.createShopCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createShopCampaignFailure())
  }
}

function* updateShopCampaignWorker({ payload }) {
  try {
    const { data: body } = payload
    const data = yield call(api.updateShopCampaign, body)
    if (data) {
      yield put(actions.updateShopCampaignSuccess(body))
    } else {
      yield put(actions.updateShopCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopCampaignFailure())
  }
}

function* createShopOfferWorker({ payload }) {
  try {
    const data = yield call(api.createShopOffer, payload)
    if (data) {
      yield put(actions.createShopOfferSuccess(payload))
    } else {
      yield put(actions.createShopOfferFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createShopOfferFailure())
  }
}

function* updateShopOfferWorker({ payload }) {
  try {
    const data = yield call(api.updateShopOffer, payload)
    if (data) {
      yield put(actions.updateShopOfferSuccess(payload))
    } else {
      yield put(actions.updateShopOfferFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopOfferFailure())
  }
}

function* deleteCampaignWorker({ payload }) {
  try {
    const data = yield call(api.deleteCampaign, payload)
    if (data) {
      yield put(actions.deleteCampaignSuccess(payload))
    } else {
      yield put(actions.deleteCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteCampaignFailure())
  }
}

function* deleteAdPackageWorker({ payload }) {
  try {
    const data = yield call(api.deleteAdPackage, payload)
    if (data) {
      yield put(actions.deleteAdPackageSuccess(payload))
    } else {
      yield put(actions.deleteAdPackageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteAdPackageFailure())
  }
}

function* deleteShopOfferWorker({ payload }) {
  try {
    const data = yield call(api.deleteShopOffer, payload)
    if (data) {
      yield put(actions.deleteShopOfferSuccess(payload))
    } else {
      yield put(actions.deleteShopOfferFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteShopOfferFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchShops, fetchShopsWorker),
    takeLatest(actions.fetchLocalizedShops, fetchLocalizedShopsWorker),
    takeLatest(actions.fetchShopCampaignDeals, fetchShopCampaignDealsWorker),
    takeLatest(actions.fetchShopAdPackages, fetchShopAdPackagesWorker),
    takeLatest(actions.fetchShopPrograms, fetchShopProgramsWorker),
    takeLatest(actions.updateShop, updateShopWorker),
    takeLatest(actions.updateLocalizedShop, updateLocalizedShopWorker),
    takeLatest(
      actions.updateShopCouponsSettings,
      updateShopCouponsSettingsWorker
    ),
    takeLatest(actions.createShopCampaign, createShopCampaignWorker),
    takeLatest(actions.updateShopCampaign, updateShopCampaignWorker),
    takeLatest(actions.fetchShopOffers, fetchShopOffersWorker),
    takeLatest(actions.createShopOffer, createShopOfferWorker),
    takeLatest(actions.updateShopOffer, updateShopOfferWorker),
    takeLatest(actions.deleteCampaign, deleteCampaignWorker),
    takeLatest(actions.deleteAdPackage, deleteAdPackageWorker),
    takeLatest(actions.deleteShopOffer, deleteShopOfferWorker),
  ])
}
