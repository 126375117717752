import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  page: 1,
  pageCount: 1,
  adPackages: [],
  sorter: {},
  filters: {},
  search: '',
  region: '',
}

const adPackagesSlice = createSlice({
  name: 'adPackages',
  initialState,
  reducers: {
    fetchAdPackagesSuccess: (state, { payload }) => ({
      ...state,
      adPackages: payload.adPackages,
      page: payload.page,
      pageCount: payload.pageCount,
      sorter: payload.sorter,
      filters: payload.filters,
      search: payload.search,
    }),
    resetFiltersAndSorter: (state) => ({
      ...state,
      sorter: initialState.sorter,
      filters: initialState.filters,
      search: initialState.search,
    }),
    createAdPackageSuccess: (state, { payload }) => {
      state.adPackages.unshift(payload)
    },
    updateAdPackageSuccess: (state, { payload }) => {
      state.adPackages = state.adPackages.map((adPackage) =>
        adPackage.id === payload.id ? payload : adPackage
      )
    },
    deleteAdPackageSuccess: (state, { payload }) => {
      state.adPackages = state.adPackages.filter(
        (adPackage) => adPackage.id !== payload
      )
    },
    saveRegion: (state, { payload }) => ({
      ...state,
      region: payload,
    }),
    resetRegion: (state) => ({
      ...state,
      region: initialState.region,
    }),
    clearData: () => initialState,
  },
})

export default adPackagesSlice
