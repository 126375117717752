import normalized from 'json-api-normalizer'
import isArray from 'lodash/isArray'
import { ENTITY_NAMES } from 'const/api'
import { formatAdPackage } from './formatters'

export const normalizeAdPackages = (data) => {
  const normalizedAdPackages = normalized(data, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  })

  const assets = normalizedAdPackages[ENTITY_NAMES.adPackageAsset]

  if (isArray(data?.data)) {
    return data?.data.map((adPackage) =>
      formatAdPackage(normalizedAdPackages.ad_packages?.[adPackage.id], assets)
    )
  }

  return Object.keys(normalizedAdPackages.ad_packages).map((id) =>
    formatAdPackage(normalizedAdPackages.ad_packages[id], assets)
  )
}

export const denormalizeAdPackage = (adPackage) => {
  const { id, retailer, assetId, ...rest } = adPackage

  let retailerData = null
  if (retailer) {
    retailerData = {
      retailer: {
        data: {
          type: 'retailers',
          id: retailer?.value,
        },
      },
    }
  }

  let assetData = null
  if (assetId) {
    assetData = {
      ad_package_asset: {
        data: {
          type: 'ad_package_assets',
          id: assetId,
        },
      },
    }
  }

  const mainData = {
    data: {
      type: 'ad_packages',
      id,
      attributes: {
        ...rest,
      },
    },
  }

  return {
    data: Object.assign(
      mainData.data,
      retailerData || assetData
        ? { relationships: { ...assetData, ...retailerData } }
        : {}
    ),
  }
}

export const denormalizeAdPackageAsset = (asset) => {
  const { ad_package_asset_id, ...rest } = asset
  return {
    data: {
      type: 'ad_package_assets',
      id: ad_package_asset_id,
      attributes: {
        ...rest,
      },
    },
  }
}

export const createAdPackageAssetData = (asset) => {
  return {
    data: {
      type: 'ad_package_assets',
      attributes: {
        ...asset,
      },
    },
  }
}
