import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import userBan from './userBanSlice'
import layout from './layoutSlice'
import { reducer as user } from 'domains/user'
import { reducer as general } from 'domains/general'
import { reducer as adPackages } from 'domains/adPackages'
import { reducer as retailers } from 'domains/retailers'
import { reducer as features } from 'domains/features'
import { reducer as products } from 'domains/products'
import { reducer as landingPages } from 'domains/landingPages'
import { reducer as marketingPages } from 'domains/marketingPages'
import { reducer as SEOPages } from 'domains/SEOPages'
import { reducer as coupons } from 'domains/coupons'
import { reducer as bonuses } from 'domains/bonuses'
import { reducer as admin } from 'domains/admin'
import { reducer as payments } from 'domains/payments'
import { reducer as mobileApp } from 'domains/mobileApp'
import { reducer as extensionVersions } from 'domains/extensionVersions'
import { reducer as rules } from 'domains/rules'
import { reducer as search } from 'domains/search'
import { reducer as giveaways } from 'domains/giveaways'
import { reducer as developers } from 'domains/developers'
import { reducer as campaigns } from 'domains/campaigns'
import { reducer as pitaSettings } from 'domains/pitaSettings'
import { reducer as shops } from 'domains/shops'
import { reducer as adminLogs } from 'domains/adminLogs'
import { reducer as transactions } from 'domains/transactions'
import { reducer as paymentTransactions } from 'domains/paymentTransactions'
import modals from './modals/reducer'
import forms from './forms/reducer'

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    general,
    retailers,
    user,
    modals,
    rules,
    mobileApp,
    extensionVersions,
    admin,
    payments,
    forms,
    features,
    products,
    coupons,
    bonuses,
    userBan,
    adPackages,
    layout,
    search,
    landingPages,
    marketingPages,
    SEOPages,
    giveaways,
    developers,
    campaigns,
    pitaSettings,
    shops,
    adminLogs,
    transactions,
    paymentTransactions,
  })

const rootReducer = (history) => (state, action) => {
  if (action.type === 'user/userLogout') {
    state = undefined
  }

  return appReducer(history)(state, action)
}

export default rootReducer
