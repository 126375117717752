import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrentShop } from 'domains/shops/selectors'
import { SearchWrapper, Wrapper, Info } from './Shops.styles'

import SearchSelectShops from 'components/SearchSelect/SearchSelectShops'
import ShopsTabs from './ShopsTabs'
import ShopCard from './ShopCard/ShopCard'

export const HEADER_OFFSET = {
  offsetHeader: 49,
}

const Shops = () => {
  const dataSource = useSelector(getCurrentShop)

  return (
    <Wrapper>
      <SearchWrapper>
        <SearchSelectShops />
      </SearchWrapper>
      {dataSource ? (
        <>
          <ShopCard shop={dataSource} />
          <ShopsTabs />
        </>
      ) : (
        <Info>Choose shop to see all features</Info>
      )}
    </Wrapper>
  )
}

export default Shops
