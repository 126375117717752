import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as shopsActions,
  formatters as shopsFormatters,
  selectors as shopsSelectors,
} from 'domains/shops'
import * as actions from 'actions/views/shops'
import { FIELD_NAMES } from 'const/forms'
import { message } from 'antd'
import {
  formatCampaignForUpdate,
  formatShopOfferForRequests,
} from 'domains/shops/formatters'
import { SHOP_SUB_ENTITIES } from '../../domains/shops/const'

function* fetchShopsWorker({ payload } = {}) {
  try {
    const search = yield select(shopsSelectors.getSearchString)
    const body = shopsFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([put(startLoading()), put(shopsActions.fetchShops(body))])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* fetchLocalizedShopsWorker({ payload } = {}) {
  try {
    const search = yield select(shopsSelectors.getLocalizedShops)
    const body = shopsFormatters.formatPayloadForFetchLocalizedShopsRequest({
      search,
      ...payload,
    })
    yield put(shopsActions.fetchLocalizedShops(body))

    const [success] = yield race([
      take(shopsActions.fetchLocalizedShopsSuccess),
      take(shopsActions.fetchLocalizedShopsFailure),
    ])

    if (success) {
      yield put(
        shopsActions.saveFiltersAndSorter({
          ...payload,
          category: SHOP_SUB_ENTITIES.localizedShops,
        })
      )
    }
  } catch (error) {
    console.error(error)
  }
}

function* fetchShopCampaignDealsWorker({ payload }) {
  try {
    const id = yield select(shopsSelectors.getCurrentShopId)
    yield all([put(shopsActions.fetchShopCampaignDeals({ id, ...payload }))])

    const [success] = yield race([
      take(shopsActions.fetchShopCampaignDealsSuccess),
      take(shopsActions.fetchShopCampaignDealsFailure),
    ])

    if (success) {
      yield put(
        shopsActions.saveFiltersAndSorter({
          ...payload,
          category: SHOP_SUB_ENTITIES.campaignDeals,
        })
      )
    }
  } catch (error) {
    console.error(error)
  }
}

function* fetchShopOffersWorker({ payload }) {
  try {
    const id = yield select(shopsSelectors.getCurrentShopId)
    yield all([put(shopsActions.fetchShopOffers({ id, ...payload }))])

    const [success] = yield race([
      take(shopsActions.fetchShopOffersSuccess),
      take(shopsActions.fetchShopOffersFailure),
    ])

    if (success) {
      yield put(
        shopsActions.saveFiltersAndSorter({
          ...payload,
          category: SHOP_SUB_ENTITIES.shopOffers,
        })
      )
    }
  } catch (error) {
    console.error(error)
  }
}

function* fetchShopAdPackagesWorker({ payload }) {
  try {
    const id = yield select(shopsSelectors.getCurrentShopId)
    yield all([put(shopsActions.fetchShopAdPackages({ id, ...payload }))])

    const [success] = yield race([
      take(shopsActions.fetchShopAdPackagesSuccess),
      take(shopsActions.fetchShopAdPackagesFailure),
    ])

    if (success) {
      yield put(
        shopsActions.saveFiltersAndSorter({
          ...payload,
          category: SHOP_SUB_ENTITIES.adPackages,
        })
      )
    }
  } catch (error) {
    console.error(error)
  }
}

function* fetchShopProgramsWorker({ payload }) {
  try {
    const id = yield select(shopsSelectors.getCurrentShopId)
    yield put(shopsActions.fetchShopPrograms({ id, ...payload }))

    yield take([
      shopsActions.fetchShopProgramsSuccess,
      shopsActions.fetchShopProgramsFailure,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* updateShopWorker({ payload }) {
  try {
    const body = shopsFormatters.formatShopsForUpdate(payload.values)
    yield all([
      put(shopsActions.startSaving()),
      put(
        shopsActions.updateShop({
          ...body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(shopsActions.updateShopSuccess),
      take(shopsActions.updateShopFailure),
    ])

    if (success) {
      put(shopsActions.fetchLocalizedShops(payload.values[FIELD_NAMES.id]))
      message.success('Shop updated')
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* updateLocalizedShopWorker({ payload }) {
  try {
    const body = shopsFormatters.formatLocalizedShopsForUpdate(payload.values)
    yield all([
      put(shopsActions.startSaving()),
      put(
        shopsActions.updateLocalizedShop({
          data: body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(shopsActions.updateLocalizedShopSuccess),
      take(shopsActions.updateLocalizedShopFailure),
    ])

    if (success) {
      const search = yield select(shopsSelectors.getCurrentShopId)
      yield put(actions.fetchLocalizedShops({ search: search }))
      message.success('Localized shop updated')
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* updateShopCouponsSettingsWorker({ payload }) {
  try {
    yield all([
      put(shopsActions.startSaving()),
      put(shopsActions.updateShopCouponsSettings(payload)),
    ])
    const [success] = yield race([
      take(shopsActions.updateShopCouponsSettingsSuccess),
      take(shopsActions.updateShopCouponsSettingsFailure),
    ])

    if (success) {
      const search = yield select(shopsSelectors.getCurrentShopId)
      yield put(actions.fetchLocalizedShops({ search: search }))
      message.success('Localized shop updated')
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* createCampaignWorker({ payload }) {
  try {
    const body = formatCampaignForUpdate(payload.data)
    yield all([
      put(shopsActions.startSaving()),
      put(shopsActions.createShopCampaign(body)),
    ])
    const [success] = yield race([
      take(shopsActions.createShopCampaignSuccess),
      take(shopsActions.createShopCampaignFailure),
    ])

    if (success) {
      const [filters, sorter, currentShopId] = yield all([
        select(shopsSelectors.getCampaignDealsFilters),
        select(shopsSelectors.getCampaignDealsSorter),
        select(shopsSelectors.getCurrentShopId),
      ])
      if (currentShopId) {
        yield put(
          actions.fetchShopCampaignDeals({ currentShopId, filters, sorter })
        )
      }
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* updateCampaignWorker({ payload }) {
  try {
    const body = formatCampaignForUpdate(payload.data)
    yield all([
      put(shopsActions.startSaving()),
      put(
        shopsActions.updateShopCampaign({
          data: body,
          id: payload.data[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(shopsActions.updateShopCampaignSuccess),
      take(shopsActions.updateShopCampaignFailure),
    ])

    if (success) {
      const [page, filters, sorter, currentShopId] = yield all([
        select(shopsSelectors.getCampaignDealsPage),
        select(shopsSelectors.getCampaignDealsFilters),
        select(shopsSelectors.getCampaignDealsSorter),
        select(shopsSelectors.getCurrentShopId),
      ])
      if (currentShopId) {
        yield put(
          actions.fetchShopCampaignDeals({
            currentShopId,
            page,
            filters,
            sorter,
          })
        )
      }
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* createShopOfferWorker({ payload }) {
  try {
    const shopId = yield select(shopsSelectors.getCurrentShopId)
    const body = formatShopOfferForRequests({
      ...payload.values,
      shop_id: shopId,
    })
    yield all([
      put(shopsActions.startSaving()),
      put(shopsActions.createShopOffer(body)),
    ])
    const [success] = yield race([
      take(shopsActions.createShopOfferSuccess),
      take(shopsActions.createShopOfferFailure),
    ])

    if (success) {
      const [filters, sorter] = yield all([
        select(shopsSelectors.getShopOffersFilters),
        select(shopsSelectors.getShopOffersSorter),
      ])
      yield put(
        shopsActions.fetchShopOffers({
          id: shopId,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* updateShopOfferWorker({ payload }) {
  try {
    const body = formatShopOfferForRequests(payload.values)
    yield all([
      put(shopsActions.startSaving()),
      put(shopsActions.updateShopOffer(body)),
    ])
    const [success] = yield race([
      take(shopsActions.updateShopOfferSuccess),
      take(shopsActions.updateShopOfferFailure),
    ])
    if (success) {
      const [filters, sorter, currentShopId] = yield all([
        select(shopsSelectors.getShopOffersFilters),
        select(shopsSelectors.getShopOffersSorter),
        select(shopsSelectors.getCurrentShopId),
      ])
      yield put(
        shopsActions.fetchShopOffers({
          id: currentShopId,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* deleteCampaignWorker({ payload }) {
  try {
    yield all([
      put(shopsActions.startSaving()),
      put(shopsActions.deleteCampaign(payload)),
    ])
    const [success] = yield race([
      take(shopsActions.deleteCampaignSuccess),
      take(shopsActions.deleteCampaignFailure),
    ])

    if (success) {
      const [page, filters, sorter, currentShopId] = yield all([
        select(shopsSelectors.getCampaignDealsPage),
        select(shopsSelectors.getCampaignDealsFilters),
        select(shopsSelectors.getCampaignDealsSorter),
        select(shopsSelectors.getCurrentShopId),
      ])
      yield put(
        shopsActions.fetchShopCampaignDeals({
          page,
          filters,
          sorter,
          id: currentShopId,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteAdPackageWorker({ payload }) {
  try {
    yield all([
      put(shopsActions.startSaving()),
      put(shopsActions.deleteAdPackage(payload)),
    ])
    const [success] = yield race([
      take(shopsActions.deleteAdPackageSuccess),
      take(shopsActions.deleteAdPackageFailure),
    ])

    if (success) {
      const [page, currentShopId] = yield all([
        select(shopsSelectors.getAdPackagesPage),
        select(shopsSelectors.getCurrentShopId),
      ])
      yield put(
        shopsActions.fetchShopAdPackages({
          page,
          id: currentShopId,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(shopsActions.finishSaving())
  }
}

function* deleteShopOfferWorker({ payload }) {
  try {
    yield all([
      put(shopsActions.startSaving()),
      put(shopsActions.deleteShopOffer(payload)),
    ])
    const [success] = yield race([
      take(shopsActions.deleteShopOfferSuccess),
      take(shopsActions.deleteShopOfferFailure),
    ])

    if (success) {
      const [page, currentShopId] = yield all([
        select(shopsSelectors.getShopOffersPage),
        select(shopsSelectors.getCurrentShopId),
      ])
      yield put(
        shopsActions.fetchShopOffers({
          page,
          id: currentShopId,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchShops, fetchShopsWorker),
    takeLatest(actions.fetchLocalizedShops, fetchLocalizedShopsWorker),
    takeLatest(actions.updateShop, updateShopWorker),
    takeLatest(actions.updateLocalizedShop, updateLocalizedShopWorker),
    takeLatest(
      actions.updateShopCouponsSettings,
      updateShopCouponsSettingsWorker
    ),
    takeLatest(actions.fetchShopCampaignDeals, fetchShopCampaignDealsWorker),
    takeLatest(actions.fetchShopAdPackages, fetchShopAdPackagesWorker),
    takeLatest(actions.fetchShopPrograms, fetchShopProgramsWorker),
    takeLatest(actions.updateShopCampaign, updateCampaignWorker),
    takeLatest(actions.createShopCampaign, createCampaignWorker),
    takeLatest(actions.fetchShopOffers, fetchShopOffersWorker),
    takeLatest(actions.createShopOffer, createShopOfferWorker),
    takeLatest(actions.updateShopOffer, updateShopOfferWorker),
    takeLatest(actions.deleteCampaign, deleteCampaignWorker),
    takeLatest(actions.deleteAdPackage, deleteAdPackageWorker),
    takeLatest(actions.deleteShopOffer, deleteShopOfferWorker),
  ])
}
