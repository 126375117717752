import React, { useEffect } from 'react'
import { fetchShopCampaignDeals, deleteCampaign } from 'actions/views/shops'
import { Button, Spin } from 'antd'
import {
  getCampaignDealsFilters,
  getCampaignDealsPage,
  getCampaignDealsPageCount,
  getCampaignDealsSorter,
  getCurrentShopId,
  getIsCampaignDealsLoaded,
  getIsSavingActive,
  getShopCampaignDeals,
  isDataCampaignDealsFilteredOrSorted,
} from 'domains/shops/selectors'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import getColumns from './campaignsColumns'
import {
  openConfirmModal,
  openShopCampaignDealModal,
} from 'reducers/modals/actions'
import { FIELD_NAMES } from 'const/forms'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { Buttons, StyledTable } from '../Shops.styles'
import { PAGE_SIZE } from '../../../const/tables'

const SCROLL = { x: 900 }
export const HEADER_OFFSET = { offsetHeader: 1 }

function CampaignDealsTab() {
  const filters = useSelector(getCampaignDealsFilters)
  const sorter = useSelector(getCampaignDealsSorter)
  const page = useSelector(getCampaignDealsPage)
  const dataSource = useSelector(getShopCampaignDeals)
  const currentShopId = useSelector(getCurrentShopId)
  const isLoaded = useSelector(getIsCampaignDealsLoaded)
  const isSavingActive = useSelector(getIsSavingActive)
  const pageCount = useSelector(getCampaignDealsPageCount)
  const isDataFilteredOrSorted = useSelector(
    isDataCampaignDealsFilteredOrSorted
  )

  const fetchCampaignsFunc = useAction(fetchShopCampaignDeals)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openCampaignModalFunc = useAction(openShopCampaignDealModal)
  const deleteCampaignFunc = useAction(deleteCampaign)

  const onTableChange = (pagination, filters, sorter) => {
    fetchCampaignsFunc({
      search: currentShopId,
      filters,
      sorter,
      page: pagination.current,
    })
  }

  const handleResetFilters = () => {
    fetchCampaignsFunc({
      page: 1,
      filters: {},
      sorter: {},
    })
  }

  const handleEditCampaign = (id) => {
    openCampaignModalFunc({ campaignId: id })
  }

  const handleCreateCampaign = () => {
    openCampaignModalFunc()
  }

  const handleDeleteCampaign = (campaign) => {
    const callback = () => deleteCampaignFunc(campaign[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete campaign (ID: ${
        campaign[FIELD_NAMES.id]
      })? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  useEffect(() => {
    if (currentShopId) {
      fetchCampaignsFunc({
        filters,
        sorter,
      })
    }
  }, [currentShopId])

  return (
    <Spin spinning={!isLoaded && isSavingActive} size="large">
      <Buttons>
        <Button
          onClick={handleResetFilters}
          disabled={!isLoaded || !isDataFilteredOrSorted}
          style={{ marginLeft: '12px' }}
        >
          Reset filters
        </Button>
        <Button
          onClick={handleCreateCampaign}
          disabled={!isLoaded || !currentShopId}
          style={{ marginBottom: '10px' }}
        >
          Create campaign
        </Button>
      </Buttons>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleEditCampaign,
          handleDeleteCampaign
        )}
        pagination={paginationConfig}
        dataSource={dataSource || []}
        expandable={false}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Spin>
  )
}

export default CampaignDealsTab
