import React, { useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { message, Spin } from 'antd'
import first from 'lodash/first'
import { Form, Field } from 'react-final-form'
import { diff } from 'deep-object-diff'
import arrayMutators from 'final-form-arrays'
import useAction from 'utils/useAction'
import Input from 'components/Input/Input'
import Select from 'components/Select/Select'
import ImagePicker from 'components/ImagePicker'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper'
import Checkbox from 'components/Checkbox/Checkbox'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import SearchRetailers from './SearchRetailers'
import CustomRangePicker from './CustomRangePicker'
import { actions as adPackageActions } from 'domains/adPackages'
import { FlexWrapper } from './ShopAdPackage.style'
import {
  WEB_CROP_SIZE,
  MOBILE_CROP_SIZE,
  MOBILE_SEE_ALL_CROP_SIZE,
  BANNER_CROP_SIZE,
  PACKAGE_STATUS_OPTIONS,
  GENDER_OPTIONS,
  MEDIA_TYPE_OPTIONS,
} from 'const/retailers'
import {
  required,
  bodyLength,
  headerLength,
  validBannerLink,
  requireBannerField,
  requiredSalesImage,
} from 'helpers/validators'
import { FIELD_NAMES } from 'const/forms'
import { getShopAdPackageById } from 'domains/shops/selectors'
import { PACKAGE_TYPE_OPTIONS } from 'const/shops'
import { DEFAULT_MEDIA_TYPE } from 'const/adPackage'

const ShopAdPackage = ({ closeModal, id, createMode }) => {
  const updateAdPackage = useAction(adPackageActions.updateAdPackageRequest)
  const createAdPackage = useAction(adPackageActions.createAdPackageRequest)
  const resetRegionFunc = useAction(adPackageActions.resetRegion)
  const adPackage = useSelector(getShopAdPackageById)(id).attributes
  const adPackageAsset = useSelector(getShopAdPackageById)(id).ad_package_asset
  const formattedAdPackage = {
    ...adPackage,
    [FIELD_NAMES.mediaType]:
      adPackage[FIELD_NAMES.mediaType] || DEFAULT_MEDIA_TYPE,
  }

  const onSubmit = async (values) => {
    delete values.retailer_name
    delete values.regions_groups
    const changes = diff(formattedAdPackage, values)
    const textChanges = {
      [FIELD_NAMES.headerText]: changes.hasOwnProperty(FIELD_NAMES.headerText)
        ? changes[FIELD_NAMES.headerText] || null
        : undefined,
      [FIELD_NAMES.bodyText]: changes.hasOwnProperty(FIELD_NAMES.bodyText)
        ? changes[FIELD_NAMES.bodyText] || null
        : undefined,
    }

    const hasNewValidStartDate = changes.hasOwnProperty(FIELD_NAMES.startDate)
      ? values[FIELD_NAMES.startDate] &&
        !moment(changes[FIELD_NAMES.startDate]).isBefore()
      : !!values[FIELD_NAMES.startDate]

    const formattedChanges = {
      ...changes,
      // shopId,
      [FIELD_NAMES.mediaType]:
        values[FIELD_NAMES.mediaType] === DEFAULT_MEDIA_TYPE
          ? null
          : values[FIELD_NAMES.mediaType],
      [FIELD_NAMES.startDate]: hasNewValidStartDate
        ? changes[FIELD_NAMES.startDate]
        : moment().toISOString(),
    }

    const errors = !id
      ? await createAdPackage(formattedChanges)
      : await updateAdPackage({
          ...formattedChanges,
          ...textChanges,
          ad_package_asset_id: adPackageAsset.ad_package_asset_id,
          [FIELD_NAMES.regions]: values[FIELD_NAMES.regions],
          id: id.toString(),
        })
    if (errors) {
      message.error('Error occured')
    } else {
      message.success('Saved')
      closeModal()
    }
  }

  useEffect(() => {
    return () => resetRegionFunc()
  }, [])

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{ ...formattedAdPackage, id }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        form: {
          mutators: { push },
        },
        values,
      }) => (
        <Spin spinning={submitting} size="large">
          <form onSubmit={handleSubmit}>
            {id && (
              <Field
                name="id"
                label="Ad Package ID"
                disabled
                component={Input}
              />
            )}
            <Field
              required
              name={FIELD_NAMES.name}
              validate={required}
              label="Ad Package Name"
              placeholder="Name"
              component={Input}
            />
            <Field
              label="Status"
              options={PACKAGE_STATUS_OPTIONS}
              defaultValue="not_opened"
              name={FIELD_NAMES.status}
              component={Select}
            />
            <SearchRetailers
              isGlobalSelected={
                !adPackage?.[FIELD_NAMES.retailer]?.[FIELD_NAMES.countryCode]
              }
              name={adPackage?.[FIELD_NAMES.retailerName]}
            />
            <CustomRangePicker createMode={createMode} />

            <Field
              label="Gender"
              options={GENDER_OPTIONS}
              defaultValue="all_gender"
              name={FIELD_NAMES.gender}
              component={Select}
            />
            <Field
              validate={headerLength}
              name={FIELD_NAMES.headerText}
              placeholder="Header Text"
              label="Header"
              component={Input}
            />
            <Field
              validate={bodyLength}
              name={FIELD_NAMES.bodyText}
              label="Body"
              placeholder="Body Text"
              component={Input}
            />
            <Field
              name={FIELD_NAMES.mediaType}
              label="Media type (Badge)"
              defaultValue={first(MEDIA_TYPE_OPTIONS).value}
              options={MEDIA_TYPE_OPTIONS}
              component={Select}
            />
            <Field
              name={FIELD_NAMES.packageType}
              label="Package type"
              defaultValue={first(PACKAGE_TYPE_OPTIONS).value}
              options={PACKAGE_TYPE_OPTIONS}
              component={Select}
            />
            <Field
              name={FIELD_NAMES.textOnly}
              label="Text Ad"
              component={Checkbox}
            />
            {!values?.[FIELD_NAMES.textOnly] && (
              <>
                <FieldWrapper label="Sales web" required>
                  <FlexWrapper>
                    <Field
                      name={FIELD_NAMES.salesWeb1}
                      hasCropper
                      validate={requiredSalesImage(FIELD_NAMES.salesWeb2)}
                      cropSize={WEB_CROP_SIZE}
                      component={ImagePicker}
                    />
                    <Field
                      hasCropper
                      validate={requiredSalesImage(FIELD_NAMES.salesWeb1)}
                      cropSize={WEB_CROP_SIZE}
                      name={FIELD_NAMES.salesWeb2}
                      component={ImagePicker}
                    />
                  </FlexWrapper>
                </FieldWrapper>
                <FieldWrapper label="Sales mobile" required>
                  <FlexWrapper>
                    <Field
                      hasCropper
                      validate={requiredSalesImage(FIELD_NAMES.salesMobile2)}
                      cropSize={MOBILE_CROP_SIZE}
                      name={FIELD_NAMES.salesMobile1}
                      component={ImagePicker}
                    />
                    <Field
                      hasCropper
                      validate={requiredSalesImage(FIELD_NAMES.salesMobile1)}
                      cropSize={MOBILE_CROP_SIZE}
                      name={FIELD_NAMES.salesMobile2}
                      component={ImagePicker}
                    />
                  </FlexWrapper>
                </FieldWrapper>
                <FieldWrapper label="Sales mobile see all" required>
                  <FlexWrapper>
                    <Field
                      hasCropper
                      validate={requiredSalesImage(
                        FIELD_NAMES.salesMobileSeeAll2
                      )}
                      cropSize={MOBILE_SEE_ALL_CROP_SIZE}
                      name={FIELD_NAMES.salesMobileSeeAll1}
                      component={ImagePicker}
                    />
                    <Field
                      hasCropper
                      validate={requiredSalesImage(
                        FIELD_NAMES.salesMobileSeeAll1
                      )}
                      cropSize={MOBILE_SEE_ALL_CROP_SIZE}
                      name={FIELD_NAMES.salesMobileSeeAll2}
                      component={ImagePicker}
                    />
                  </FlexWrapper>
                </FieldWrapper>
                <FieldWrapper label="Banner" required>
                  <FlexWrapper>
                    <Field
                      hasCropper
                      validate={requireBannerField}
                      cropSize={BANNER_CROP_SIZE}
                      name={FIELD_NAMES.bannerImage}
                      component={ImagePicker}
                    />
                    <FlexWrapper column>
                      <Field
                        name={FIELD_NAMES.bannerText}
                        placeholder="Banner Text"
                        component={Input}
                      />
                      <Field
                        validate={validBannerLink}
                        name={FIELD_NAMES.bannerLink}
                        placeholder="Link"
                        component={Input}
                      />
                    </FlexWrapper>
                  </FlexWrapper>
                </FieldWrapper>
              </>
            )}
            <SubmitButton
              handleSubmit={handleSubmit}
              pristine={pristine}
              text="Submit"
            />
          </form>
        </Spin>
      )}
    />
  )
}

export default ShopAdPackage
